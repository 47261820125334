export const termsConstants = {
  subHeading: `# Terms & Conditions`,
  termsInstructionsText: `#### Follow the link below to read the full promotional terms & conditions`,
  termsButtonText: ``,
  termsButtonText2: `Read Terms for 2022`,
  vaultTermsText: `Vault Prepaid eftpos Card Terms and Conditions
For the purpose of these terms:


(a)	'We, Us and Our' means Vault Payment Solutions Group Pty Ltd, ABN 66 632 373 105 ('Vault')

(b)	'You' means the Card purchaser or user who has acquired the Card

(c)	'Card' means the Vault Prepaid eftpos Card issued by EML Payment Solutions Limited ABN 30 131 436 532 ('EML') and authorised by EML to be distributed by Vault.
By using the Card You agree to these Terms and Conditions herein.<br><br>


1.	If Your Card has an issue date displayed on the Card the Card must be activated within the time period specified on Your Card. Cards not activated within the timeframe specified on the Card will expire and any prepaid funds shall be forfeited. We will not give You any notice before this happens.

2.	If Your Card does not have an issue date displayed on the Card, it must be activated within the date specified on the letter you receive with Your Card. Cards not activated within the timeframe specified on the letter will expire and any prepaid funds shall be forfeited. We will not give You any notice before this happens.
3.	To activate Your Card, You must register and enter in Your Card details online at www.vaultactivation.com.au. Once activated, funds will be available by 12 pm the next business day.
4.	Following activation Your Card is only valid for 12 months. Once Your Card has expired it cannot be used or replaced and any remaining balance will be forfeited. We will not give You any notice before this happens.
5.	Your Card may be used for purchasing goods and services wherever eftpos cards are accepted for electronic transactions. 
6.	You cannot reload Your Card with any additional funds.
7.	You are responsible for checking your transaction history online and knowing Your available balance by viewing Your transaction activity. To check Your Card balance, Card expiry and full conditions of use, visit www.vaultactivation.com.au and enter Your Card details.
8.	Your Card is not a credit card and it is not linked to any deposit account with Vault, EML or otherwise.
9.	Your Card cannot be used to complete transactions that exceed the available balance on the Card. For such a transaction, You will need to pay the difference by another method, if the merchant agrees.
10.	Your Card cannot be used to obtain or redeem cash or make direct debit recurring or regular instalment payments.
11.	Authorisations may be declined at some merchants (such as gambling merchants or merchants who choose not to accept eftpos prepaid gift cards).
12.	Your Card remains Our property and We or EML may restrict or stop the use of the Card if suspicious activities are noticed. To check full conditions of use, visit www.vaultactivation.com.au. 
13.	Your Card is like cash and may not be replaced if misused, lost, stolen or damaged. You are responsible for all transactions on the Card, except where there has been fraud or negligence by Us, Our staff or agents.
14.	We not liable in any way should a payment authorisation be declined for a particular transaction, except where the authorisation has been declined due to Our act or omission.
15.	We are not liable for the use, functionality and availability of the Card or the availability of merchants who allow the use of the Card as payment.
16.	To the extent permitted by law, We:
            a)	exclude all statutory or implied conditions and warranties and any other liability We may have to You (including liability for indirect or consequential loss) that may arise under statute or at law including without limitation for breach of contract, in tort (including negligence) or under any other cause of action howsoever arising out of, or in any way related to, the use of the Card and Our goods and services 
            b)	limit Our liability under any condition or warranty which cannot be legally excluded in relation to the supply of goods or services to (at our election) replacing the goods or services or supplying equivalent goods or services again and
            c)  	make no warranties or representations about Our goods or services or the content related to Our goods and services, including but not limited to warranties or representations that such content will be complete, accurate or up-to-date or that access to the Card or related services will be uninterrupted or error-free.
17.	Obtaining any refund on Your Card transactions is subject to the policy of the specific merchant.
18.	If Your Card expires or is revoked before You have spent any funds resulting from a refund (whether or not the original transaction being refunded was made using Your Card) then You will have no access to those funds and those funds will be forfeited.
19.	If You have a problem with a purchase made with Your Card, or a dispute with a merchant, You must deal directly with the merchant involved. If You cannot resolve the dispute with the merchant, You can contact Vault Customer Service immediately during business hours or alternatively You can send an email to info@vaultpaymentsolutions.com.au.
20.	If You wish to make a complaint about Your Card you can send an email to support@emlpayments.com.au.
21.	Information will be disclosed to third parties about the Card, or transactions made with the Card, whenever permitted by law, and where necessary to operate the Card and process transactions. A full privacy policy can be viewed at https://www.vaultps.com.au/privacy.
22.	We reserve the right to change these Terms and Conditions at any time. Any changes to these Terms and Conditions can be viewed at www.vaultps.com.au. 
    `,
};
