import { Button } from "../../../shared/formElements/button";
import {
  ButtonGroupContainer,
  Line,
  SectionContainer,
} from "../../../shared/Layout.styles";
import { Markdown } from "../../../shared/markdown";
import { termsConstants } from "../../../utils/constants/terms";

export const Terms = () => {
  const {
    subHeading,
    termsInstructionsText,
    termsButtonText,
    termsButtonText2,
    vaultTermsText,
  } = termsConstants;

  return (
    <>
      <Line />
      <SectionContainer>
        <Markdown children={subHeading} align="center" />
        {process.env.REACT_APP_TERMS_TEXT !== "null" ? (
          <Markdown children={vaultTermsText} className="vaultTermsText" />
        ) : (
          <>
            <Markdown
              children={termsInstructionsText}
              align="center"
              className="termsInstructionsText"
            />
            {termsButtonText && (
              <ButtonGroupContainer align="center">
                <Button
                  onClick={() =>
                    window.location.replace(
                      process.env.REACT_APP_TERMS_LINK ?? ""
                    )
                  }
                  children={termsButtonText}
                />
              </ButtonGroupContainer>
            )}
            {termsButtonText2 && (
              <ButtonGroupContainer align="center">
                <Button
                  onClick={() =>
                    window.location.replace(
                      process.env.REACT_APP_TERMS_LINK_2 ?? ""
                    )
                  }
                  children={termsButtonText2}
                />
              </ButtonGroupContainer>
            )}
          </>
        )}
      </SectionContainer>
    </>
  );
};
